export const videos = [
  {
    name: "Adidas x Jude Bellingham",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/Bellingham_trailer_1.mp4",
    link: "https://player.vimeo.com/video/1008147484?h=556688db7f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    name: "AntsLive - Number One Candidate",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/number_one_candidate.mp4",
    link: "https://player.vimeo.com/video/1008207003?h=edcf70fdd4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    name: "House of Errors - Summer ",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/Pyrenees_Errors_Trailor.mp4",
    link: "https://player.vimeo.com/video/1008168771?h=a32181c0e2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    name: "AntsLive - Captain Ants",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/Captain_Ants.mp4",
    link: "https://player.vimeo.com/video/871482036?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    name: "Doechii & JT - Alter Ego",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/Doechii_Trailer.mp4",
    link: "https://player.vimeo.com/video/1008147872?h=0b77e925f6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    name: "AntsLive - Yes",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/Yes_Trailer.mp4",
    link: "https://player.vimeo.com/video/1008150303?h=f2e964bc24&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    name: "House of Errors - Moto",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/Moto_Trailer.mp4",
    link: "https://player.vimeo.com/video/1008148587?h=e52ed05447&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    name: "Wildfarmed x Supa Ya Ramen",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/wildfarmed_supa_ya_ramen.mp4",
    link: "https://player.vimeo.com/video/730389767?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    name: "Real Life",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/Real_Life_trailer.mp4",
    link: "https://player.vimeo.com/video/1008149486?h=1c2dee4286&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    name: "Tides",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/Tides_trailer.mp4",
    link: "https://player.vimeo.com/video/1008150091?h=69f5090ab9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    name: "AntsLive - Ooh La La",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/Ooh_La_La.mp4",
    link: "https://player.vimeo.com/video/868910837?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    name: "A$AP Nast x Reebok",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/ASAP_NAST_x_Reebok_for_website.mp4",
    link: "https://player.vimeo.com/video/729692180?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    name: "AntsLive - Tweakin",
    video:
      "https://d1b8cx5vpl3pye.cloudfront.net/video/tweakin_teaser_v2.mp4",
    link: "https://player.vimeo.com/video/729562444?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  }
];
