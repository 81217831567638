import { useEffect, useState } from "react";

import "keen-slider/keen-slider.css";
import { useKeenSlider } from "keen-slider/react";
import { IconButton, Tab, Tabs } from "@mui/material";

import styled from "@emotion/styled";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const query = {
  "1_House of Errors AW24": {
      "0": "1.jpg",
      "1": "2.jpg",
      "2": "3.jpg"
  },
  "2_House of Errors - SS24": {
      "0": "1.jpg",
      "1": "2.jpg",
      "2": "3.jpg",
      "3": "4.jpg"
  },
  "3_Flo - Album Creative": {
      "0": "1.jpg",
      "1": "2.jpg",
      "2": "3.jpg",
      "3": "4.jpg"
  },
  "4_House of Errors - Moto": {
      "0": "1.jpg",
      "1": "2.jpg",
      "2": "3.jpg",
      "3": "4.jpg",
      "4": "5.jpg"
  },
  "5_Adidas x Jude Bellingham": {
      "0": "1.jpg",
      "1": "2.jpg",
      "2": "3.jpg"
  },
  "6_Doechii & JT - Alter Ego": {
      "0": "1.jpg",
      "1": "2.jpg"
  },
  "7_House of Errors Denim Capsule": {
      "0": "1.jpg",
      "1": "2.jpg"
  },
  "8_House of Errors - London Capsule": {
      "0": "1.jpg",
      "1": "2.jpg",
      "2": "3.jpg"
  },
  "9_AntsLive - Real Life": {
      "0": "1.jpg"
  },
  "10_House of Errors - Summer Postcards": {
      "0": "1.jpg",
      "1": "2.jpg"
  },
  "11_House of Errors - Fleece Capsule": {
      "0": "1.jpg",
      "1": "2.jpg",
      "2": "3.jpg"
  },
  "12_Burberry": {
      "0": "1.jpg",
      "1": "2.jpg"
  },
  "13_A$AP Nast x Reebok": {
      "0": "1.jpg",
      "1": "2.jpg",
      "2": "3.jpg",
      "3": "4.jpg"
  },
  "14_i-D": {
      "0": "1. i-D.jpg",
      "1": "2. i-D.jpg",
      "2": "3. i-D.jpg"
  },
  "15_Replica Man": {
      "0": "1.jpg",
      "1": "2.jpg",
      "2": "3.jpg",
      "3": "4.jpg"
  },
  "16_Helene Studios": {
      "0": "1.jpg",
      "1": "2.jpg"
  },
  "17_Recens Paper": {
      "0": "1.jpg",
      "1": "2.jpg"
  },
  "18_Helmut Lang": {
      "0": "1.jpg",
      "1": "2.jpg"
  },
  "19_i-D - Skate Kitchen": {
      "0": "1.jpg",
      "1": "2.jpg",
      "2": "3.jpg",
      "3": "4.jpg"
  },
  "20_TooHot Limited": {
      "0": "1.jpg",
      "1": "2.jpg",
      "2": "3.jpg",
      "3": "4.jpg"
  }
};

const images = Object.keys(query)
  .map((a) =>
    Object.keys(query[a]).map((b) => ({
      url: `https://d1b8cx5vpl3pye.cloudfront.net/photo/${a}/${query[a][b]}`,
      shoot: a,
    }))
  )
  .flatMap((a) => a);

const CustomTabs = styled(Tabs)({
  flex: 1,
  minHeight: 28,
});

const CustomTab = styled(Tab)(({ theme }) => ({
  color: "#9A9A9A",
  fontSize: "1em",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.8em",
    lineHeight: "inherit",
    minHeight: 26,
  },
  textTransform: "none",
  minHeight: "8px",
  lineHeight: "16px",
}));

const ContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  backgroundColor: "rgba(0,0,0,0.8)",
  top: 50,
  [theme.breakpoints.down("md")]: {
    top: 50,
  },
  zIndex: 5,
  width: "100%",
  position: "fixed",
}));

const ContentTabs = ({ selected, slideToFirstOfShoot }) => {
  const [currentTab, setCurrentTab] = useState(selected);

  const handleChange = (_event, newValue) => {
    setCurrentTab(newValue);
    slideToFirstOfShoot(newValue);
  };

  useEffect(() => setCurrentTab(selected), [selected]);

  const tabs = [...new Set(images.map(({ shoot }) => shoot))];

  return (
    <ContainerDiv>
      <IconButton
        onClick={() => {
          setCurrentTab(tabs[tabs.findIndex((a) => a === currentTab) - 1]);
          slideToFirstOfShoot(
            tabs[tabs.findIndex((a) => a === currentTab) - 1]
          );
        }}
        disabled={tabs.findIndex((a) => a === currentTab) === 0}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <CustomTabs
        value={currentTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
      >
        {tabs.map((a) => (
          <CustomTab value={a} label={a.split("_")[1]} wrapped />
        ))}
      </CustomTabs>
      <IconButton
        onClick={() => {
          setCurrentTab(tabs[tabs.findIndex((a) => a === currentTab) + 1]);
          slideToFirstOfShoot(
            tabs[tabs.findIndex((a) => a === currentTab) + 1]
          );
        }}
        disabled={tabs.findIndex((a) => a === currentTab) === tabs.length - 1}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </ContainerDiv>
  );
};

const KeenSlider = styled("div")(({ theme }) => ({
  maxWidth: "fit-content",
  width: "auto",
}));

const KeenSliderPhoto = styled("img")(({ theme }) => ({
  "@keyframes animateBg": {
    "0%": { backgroundPosition: "100% 0%" },
    "100%": { backgroundPosition: "0% 0%" },
  },
  animation: "animateBg 1s linear infinite",
  backgroundImage: "linear-gradient(90deg,#000000,#424242,#000000,#424242)",
  backgroundSize: "300% 100%",
  "&:after": {
    height: "100vh",
  },
  minHeight: 100,
}));

const AnimatedDiv = styled("div")(({ theme }) => ({
  "@keyframes animateBg": {
    "0%": { backgroundPosition: "100% 0%" },
    "100%": { backgroundPosition: "0% 0%" },
  },
  animation: "animateBg 1s linear infinite",
  backgroundImage: "linear-gradient(90deg,#000000,#424242,#000000,#424242)",
  backgroundSize: "300% 100%",
}));

const SurrounderDiv = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: 110,
  [theme.breakpoints.down("md")]: {
    marginTop: 95,
  },
}));

const PhotoContent = () => {
  const [options, setOptions] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [refCallback, slider] = useKeenSlider(options);

  const slideToFirstOfShoot = (shoot) => {
    const index = images.findIndex((item) => item.shoot === shoot);
    slider.current.moveToIdx(index);
  };

  useEffect(() => {
    setTimeout(() => {
      setOptions({
        slideChanged: (element) => {
          setCurrentSlide(element.track.details.rel);
        },
        animationEnded: () => {
          slider && slider.current.update();
        },
        mode: "snap",
        slides: { perView: "auto" },
        defaultAnimation: {
          duration: 1000,
        },
        renderMode: "performance",
      });
    }, 500);
  }, [slider]);

  return (
    <SurrounderDiv>
      <ContentTabs
        selected={images[currentSlide].shoot}
        slideToFirstOfShoot={slideToFirstOfShoot}
      />
      <KeenSlider
        ref={refCallback}
        className="keen-slider"
        style={{ position: "fixed", height: "85%" }}
      >
        {images.map((image, idx) => (
          <div
            className="keen-slider__slide"
            style={{
              overflow: "visible",
              maxWidth: "100vw",
              display: "flex",
              width: "auto",
            }}
            key={idx}
          >
            {Math.abs(idx - currentSlide) <= 6 ? ( // render previous and next 6 images
              <KeenSliderPhoto
                alt="test"
                loading="eager"
                src={image.url}
                style={{
                  alignSelf: "center",
                  maxWidth: 500,
                  width: "100vw",
                }}
              />
            ) : (
              <AnimatedDiv
                style={{
                  alignSelf: "center",
                  maxWidth: 500,
                  width: "100vw",
                }}
              />
            )}
          </div>
        ))}
      </KeenSlider>
      <IconButton
        onClick={() => slider.current.prev()}
        style={{ position: "absolute", zIndex: 3, top: "50%" }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          slider.current.next();
        }}
        style={{ position: "absolute", zIndex: 3, top: "50%", right: 0 }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </SurrounderDiv>
  );
};

export default PhotoContent;
